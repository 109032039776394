/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import emty from '@/components/vis/emty';
export default {
  name: 'InvitationList',
  components: {
    emty: emty
  },
  data: function data() {
    return {
      currDate: '',
      visitList: [],
      // emptyShow: '暂无访客预约',
      curPage: 1,
      //当前页
      totalPage: 0,
      //总页数
      pageNum: 20,
      //每页条数
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      cfmType: 0,
      //0 被访人同意 1 被访人拒绝 2 门卫同意 3 门卫--富维的
      dateStr: '',
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      initFlag: true,
      curMonth: '',
      startDate: '',
      endDate: '',
      // 0 审批中 1 已通过 2 已拒绝 3 已邀请 4 已确认 5 已登记 7 已访问 8 访问中 9已访问 10 失效
      colors: ['#FFB033', '#79D012', '#DA1326', '#3399FF', '#3399FF', '#3399FF', '#999999', '#1341A8', '#FFB033', '#999'],
      Statename: ['审批中', '已通过', '已拒绝', '已邀请', '已确认', '已登记', '已验证', '已验证', '访问中', '已访问', '已失效', '已取消']
    };
  },
  computed: {},
  methods: {
    getSysDate: function getSysDate() {
      var _this2 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.currDate = data.sysDate;
          _this2.dateStr = _this2.curMonth = data.sysDate.substring(0, 7);
          _this2.startDate = _this2.$utils.DateUtils.getMonthStart(data.sysDate);
          _this2.endDate = _this2.$utils.DateUtils.getMonthEnd(data.sysDate);

          if (_this2.$params.overtimeMeals == 0) {
            _this2.getVisitList(_this2.startDate, _this2.endDate, _this2.curPage);
          } else {
            _this2.getVisitLists(_this2.startDate, _this2.endDate, _this2.curPage);
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    // =========计算星期几
    getweekday: function getweekday(date) {
      date = date.replace(/-/g, '/');
      var weekArray = ['周天', '周一', '周二', '周三', '周四', '周五', '周六'];
      var week = weekArray[new Date(date).getDay()]; //注意此处必须是先new一个Date

      return week;
    },
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.visitList = [];
      this.curPage = 1;

      if (this.$params.overtimeMeals == 0) {
        this.getVisitList(this.startDate, this.endDate, this.curPage);
      } else {
        this.getVisitLists(this.startDate, this.endDate, this.curPage);
      }
    },
    onPullingUp: function onPullingUp() {
      if (this.isPullUp) return;
      this.isPullUp = true;
      this.curPage++;

      if (this.curPage > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.$params.overtimeMeals == 0) {
        this.getVisitList(this.startDate, this.endDate, this.curPage);
      } else {
        this.getVisitLists(this.startDate, this.endDate, this.curPage);
      }
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    showPlugin: function showPlugin() {
      var _this = this; // 需要注意 onCancel 和 onConfirm 的 this 指向


      this.$vux.datetime.show({
        cancelText: '取消',
        confirmText: '确定',
        format: 'YYYY-MM',
        value: this.curMonth,
        onConfirm: function onConfirm(val) {
          _this.startDate = _this.$utils.DateUtils.getMonthStart(val);
          _this.endDate = _this.$utils.DateUtils.getMonthEnd(val);
          _this.dateStr = val;
          _this.curPage = 1;
          _this.visitList = [];

          if (_this.$params.overtimeMeals == 0) {
            _this.getVisitList(_this.startDate, _this.endDate, _this.curPage);
          } else {
            _this.getVisitLists(_this.startDate, _this.endDate, _this.curPage);
          }
        }
      });
    },
    getVisitList: function getVisitList(sdate, edate, curPage) {
      var _this3 = this;

      this.emptyShow = false;
      this.$api.Visit.selectListPageByEntity({
        pageIndex: curPage,
        pageSize: this.pageNum,
        search: {
          startDate: sdate,
          endDate: edate,
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);

          _this3.updateScroll(false);
        } else {
          var _this3$visitList;

          (_this3$visitList = _this3.visitList).push.apply(_this3$visitList, _toConsumableArray(data.records));

          _this3.totalPage = data.totalPage;

          if (_this3.visitList.length === 0) {
            _this3.emptyShow = true;
          } else {
            _this3.heavyArr(_this3.visitList);

            var _iterator = _createForOfIteratorHelper(_this3.visitList),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var item = _step.value;

                if (item.visKssj) {
                  _this3.$set(item, 'weekDay', _this3.$utils.DateUtils.getWeekDaysCn(item.visKssj.substring(0, 10)));
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          }

          _this3.updateScroll(false);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    // -------------一起富维的---------------------
    getVisitLists: function getVisitLists(sdate, edate, curPage) {
      var _this4 = this;

      this.emptyShow = false; // this.$utils.Tools.loadShow();

      this.$api.Visit.selectListPageByEntity_fu({
        pageIndex: curPage,
        pageSize: this.pageNum,
        search: {
          startDate: sdate,
          endDate: edate,
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);

          _this4.updateScroll(false);
        } else {
          var _this4$visitList;

          (_this4$visitList = _this4.visitList).push.apply(_this4$visitList, _toConsumableArray(data.records));

          _this4.totalPage = data.totalPage;

          if (_this4.visitList.length === 0) {
            _this4.emptyShow = true;
          } else {
            _this4.heavyArr(_this4.visitList);

            var _iterator2 = _createForOfIteratorHelper(_this4.visitList),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var item = _step2.value;

                if (item.visKssj) {
                  _this4.$set(item, 'weekDay', _this4.$utils.DateUtils.getWeekDaysCn(item.visKssj.substring(0, 10)));
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }

            if (_this4.$params.overtimeMeals == 1) {
              _this4.visitList.some(function (item) {
                if (item.cfmType == 0) {
                  item.state = 0;
                }
              });
            }
          }

          _this4.updateScroll(false);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    heavyArr: function heavyArr(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].visKssj.substring(0, 10) == arr[j].visKssj.substring(0, 10)) {
            arr[j].visKssj = '';
          } //已拒绝/已访问/已失效/已取消状态设置code为0


          if (arr[i].state == 2 || arr[i].state == 9 || arr[i].state == 10 || arr[i].state == 11) {
            arr[i].code = 0;
          } else {
            arr[i].code = 1;
          } //最后一个节点设置linecode为0


          if (i < arr.length - 1) {
            arr[i].linecode = 1;
          } else {
            arr[i].linecode = 0;
          }
        }
      }

      return arr;
    },
    itemClick: function itemClick(item) {
      if (this.$params.overtimeMeals == 0) {
        this.$router.push({
          path: '/VisitorDetail',
          query: {
            xh: item.xh
          }
        });
      } else {
        this.$router.push({
          path: '/VisitorDetail_fuwei',
          query: {
            xh: item.xh
          }
        });
      }
    },
    orderClick: function orderClick() {
      this.$router.push('/CompleteVisitorInfo');
    }
  },
  created: function created() {
    this.getSysDate();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};