/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
export default {};